import React from 'react';
// import WelcomeImage from 'assets/images/welcome_bg_gradient.png';
import WelcomeImage from 'assets/images/image2.jpg';
import PartnerImg from 'assets/images/partner_img.svg';
// import Lenovo from 'assets/images/lenovo.png';
import Lenovo from 'assets/images/veem.svg';
import PrimaryButton from 'components/atoms/Button';
import { domIds } from 'common/constants';
import { StyledGradient } from 'components/atoms/Gradient';

import {
  Wrapper,
  Img,
  Title,
  Text,
  PartnerBlock,
  PartnerText,
  PartnerImage,
  Content,
  ImagesWrapper, BlackShadow,
} from './styled';

const WelcomeSection = () => {
  return (
    <Wrapper id={domIds.welcome}>
      <Img src={WelcomeImage} alt='header' />
      {/*<StyledGradient />*/}
      <BlackShadow/>
      <Content>
        <Title>Soluzioni IT per il retail</Title>
        <Text>
          Con oltre 20 anni di esperienza nel cloud e nello sviluppo software, possiamo fornire servizi sia pacchettizati sia personalizzati.
        </Text>
        {/*<PrimaryButton w='317px'>Request a quote</PrimaryButton>*/}
        <PartnerBlock>
          <PartnerText>PARTNER</PartnerText>
          <ImagesWrapper>
            <PartnerImage src={PartnerImg} alt='partner' mobileMb={12} />
            <PartnerImage height={'80px'} src={Lenovo} alt='lenovo' ml={20} />
          </ImagesWrapper>
        </PartnerBlock>
      </Content>
    </Wrapper>
  );
};

export default WelcomeSection;
