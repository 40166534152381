import React, { useState } from 'react';
import { domIds } from 'common/constants';
import { StyledSubTitle, StyledTitle } from 'components/atoms/Titles';
// import ProjectBgImage from 'assets/images/projects_bg.png';
import ProjectBgImage from 'assets/images/image1.jpg';

import {
  Wrapper,
  Img,
  Content,
  Form,
  Input,
  Button, ErrorMessage,
} from './styled';

const ProjectsSection = () => {

  const [data, setData] = useState({
    email: '',
    password: ''
  });
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setShowErrorMessage(true);
  };

  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    setData(s => ({
      ...s,
      [name]: value
    }));
    setShowErrorMessage(false);
  };

  return (
    <Wrapper id={domIds.projects}>
      <Img src={ProjectBgImage} alt='projects' />
      <Content>
        <StyledSubTitle fontSize={32} mb={12} color='#000'>
          Login ecommerce
        </StyledSubTitle>
      </Content>
      <Wrapper>
        <Form onSubmit={handleSubmit}>
          <Input
            autoComplete='none'
            type='email'
            name='email'
            value={data.email}
            placeholder={'Email'}
            onChange={handleChange}
          />
          <Input
            type='password'
            name='password'
            placeholder={'Password'}
            value={data.password}
            onChange={handleChange}
          />
          {showErrorMessage && <ErrorMessage>L’utente non e’ stato trovato</ErrorMessage>}
          <Button>Login</Button>
        </Form>
      </Wrapper>
    </Wrapper>
  );
};

export default ProjectsSection;
