import React from 'react';

import { ReactComponent as LogoImage2 } from 'assets/images/logo_2.svg';
import { ReactComponent as LogoImage3 } from 'assets/images/logo_3.svg';
import { ReactComponent as LogoImage } from 'assets/images/logo_1.svg';
import { ReactComponent as Logo } from 'assets/images/icons/logo-dokutech.svg';

import { FirstLogo, LogoBox } from './styled';

const HeaderLogo = () => {
  return (
    <LogoBox>
      <FirstLogo>
        <Logo width={'200px'} height={'80px'}/>
      </FirstLogo>
    </LogoBox>
  );
};

export default HeaderLogo;
