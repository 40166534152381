import React from 'react';
import { StyledContainer } from 'components/atoms/Container/styled';
import HeaderLogo from 'components/atoms/HeaderLogo';
import { domIds } from 'common/constants';
import { StyledGradient } from 'components/atoms/Gradient';

import { ReactComponent as GeoIcon } from 'assets/images/icons/geo.svg';
import { ReactComponent as MailIcon } from 'assets/images/icons/mail.svg';
import { ReactComponent as TelIcon } from 'assets/images/icons/tel.svg';

import { ReactComponent as MailNewIcon } from 'assets/images/mail.8c797d84.svg';
import { ReactComponent as NewTelIcon } from 'assets/images/tel.c1df0838.svg';
import { ReactComponent as NewGeoIcon } from 'assets/images/geo.e32511a0.svg';


import FooterBg from 'assets/images/footer_bg.png';

import {
  Wrapper,
  Content,
  Img,
  ContentItem,
  Title,
  InfoList,
  InfoListItem,
  InfoListItemIcon,
  InfoListItemText,
  FooterCopyrightWrapper,
  Copyright,
  RedText,
  IconWrapper,
} from './styled';

const Footer = () => {
  return (
    <Wrapper id={domIds.contact_us}>
      <Img src={FooterBg} alt='footer' />
      <StyledGradient />
      <StyledContainer
        style={{ position: 'relative', height: '100%', zIndex: 2 }}
      >
        <Content>
          {/*<ContentItem isForm={true}>*/}
          {/*  <FooterForm />*/}
          {/*</ContentItem>*/}
          <ContentItem
            style={{ flexDirection: 'column', justifyContent: 'center' }}
          >
            <Title>Mettiti in contatto con noi</Title>
            <InfoList>
              <InfoListItem>
                <IconWrapper>
                  <InfoListItemIcon as={MailNewIcon} />
                </IconWrapper>
                <InfoListItemText as='a' href='mailto:info@ritail.hk'>
                  info@dokutech.it
                </InfoListItemText>
              </InfoListItem>
              <InfoListItem>
                <IconWrapper>
                  <InfoListItemIcon as={NewGeoIcon} />
                </IconWrapper>
                <InfoListItemText
                  as='a'
                  href='https://goo.gl/maps/ZJK2JN3hr3zAMxXh9'
                  rel='noreferrer noopener'
                  target='_blank'
                >
                 {/* 19-27 Wyndham Street <br/>
                  Room 503, Wilson House*/}
                  Bagnatica (BG) via Roma 10
                </InfoListItemText>
              </InfoListItem>
              <InfoListItem>
                <IconWrapper>
                  <InfoListItemIcon as={NewTelIcon} />
                </IconWrapper>
                <InfoListItemText as='a' href='tel:+852 2319 4169'>
                  0350460018
                </InfoListItemText>
              </InfoListItem>

              {/*<InfoListItem isButton={true}>*/}
              {/*  <InfoListItemText isButton={true} as='a' href='mailto:info@ritail.hk'>*/}
              {/*    Contact Us*/}
              {/*  </InfoListItemText>*/}
              {/*</InfoListItem>*/}
            </InfoList>
          </ContentItem>
        </Content>
      </StyledContainer>
      <FooterCopyrightWrapper>
        <StyledContainer
          style={{
            justifyContent: 'space-between',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <HeaderLogo />
          <Copyright>
            <p>
              P.IVA 04566830164
            </p>
            <p>
              © {new Date().getFullYear()} - All rights reserved
            </p>
          </Copyright>
        </StyledContainer>
      </FooterCopyrightWrapper>
    </Wrapper>
  );
};

export default Footer;
