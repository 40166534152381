import React, { useState, useCallback } from 'react';

import { ReactComponent as ThunderIcon } from 'assets/images/icons/thunder.svg';
import { ReactComponent as WebAgenciesIcon } from 'assets/images/icons/web_agencies.svg';
import { ReactComponent as ServiceProvidersIcon } from 'assets/images/icons/service_providers_icon.svg';

import LoadingImage from 'assets/images/image_loading.svg';

import WebAgenciesImage from 'assets/images/web_agency.svg';
import RetailsImage from 'assets/images/retails.svg';
import ServiceProvidersImage from 'assets/images/service_providers.svg';

import {
  Wrapper,
  SwitchBlockItem,
  SwitchItemWrapper,
  SwitchItem,
  SwitchItemTitle,
  SwitchItemDescription,
  SwitchItemImage,
  SwitchItemIcon,
} from './styled';

const items = [
  {
    id: 'retailers',
    title: 'Rivenditori',
    icon: ThunderIcon,
    description: [
     'Le nostre soluzioni sono operative in molti centri commerciali e premium outlet.'
    ],
  },
  {
    id: 'web_agencies',
    title: 'Agenzie web',
    icon: WebAgenciesIcon,
    description: [
      'Costruiamo e gestiamo soluzioni cloud per fornire\n' +
      'servizi web ottimali',
    ],
  },
  {
    id: 'service_providers',
    title: 'Fornitori di servizi',
    icon: ServiceProvidersIcon,
    description: [
    'Il nostro comprovato know-how nell\'enterprise networking può aiutare a fornire servizi e-mail e di backup particolarmente sofisticati'
    ],
  },
];

const getImg = (activeKey) => {
  switch (activeKey) {
    case 'retailers':
      return RetailsImage;
    case 'web_agencies':
      return WebAgenciesImage;
    case 'service_providers':
      return ServiceProvidersImage;
    default:
      return null;
  }
};

const ItemImage = ({ activeKey }) => {
  const getImg = () => {
    switch (activeKey) {
      case 'retailers':
        return RetailsImage;
      case 'web_agencies':
        return WebAgenciesImage;
      case 'service_providers':
        return ServiceProvidersImage;
      default:
        return null;
    }
  };

  return <SwitchItemImage src={getImg(activeKey)} />;
};

const CustomerSwitcher = ({ activeKey, onClick }) => {
  const [loadedStatuses, setLoadedStatuses] = useState({
    retailers: false,
    web_agencies: false,
    service_providers: false,
  });

  const onLoading = useCallback((key) => {
    setLoadedStatuses((state) => ({
      ...state,
      [key]: true,
    }));
  }, []);

  return (
    <Wrapper>
      <SwitchBlockItem>
        {items.map((i) => (
          <SwitchItemWrapper
            role='presentation'
            active={activeKey === i.id}
            key={i.id}
            onClick={() => onClick(i.id)}
          >
            <SwitchItem active={activeKey === i.id} >
              <SwitchItemIcon as={i.icon} />
              <div style={{ paddingTop: 3 }}>
                <SwitchItemTitle>{i.title}</SwitchItemTitle>
                <SwitchItemDescription>{i.description}</SwitchItemDescription>
              </div>
            </SwitchItem>
          </SwitchItemWrapper>
        ))}
      </SwitchBlockItem>
      <SwitchBlockItem isImage={true}>
        <SwitchItemImage
          src={loadedStatuses[activeKey] ? getImg(activeKey) : LoadingImage}
          onLoad={() => onLoading(activeKey)}
        />
      </SwitchBlockItem>
    </Wrapper>
  );
};

export default CustomerSwitcher;
