import styled from 'styled-components';
import { respondTo } from 'styles/_respondTo';

export const Wrapper = styled.section`
  min-height: 100vh;
  padding: 150px 0;
  //margin-top: 500px;
  ${respondTo.smallMobile`
    padding: 75px 0;
  `}
`;
