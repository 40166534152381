import React, { Fragment, useState, useCallback } from 'react';

import EnterpriseImg from 'assets/images/enterprise_working.svg';
import ManageCloudImg from 'assets/images/manage_cloud.svg';
import SoftwareImg from 'assets/images/software_dev.svg';
import LoadingImage from 'assets/images/image_loading.svg';

import { StyledContainer } from 'components/atoms/Container/styled';

import {
  SwitchTabItem,
  Wrapper,
  SwitchTabWrapper,
  ContentText,
  ContentTitle,
  ContentWrapper,
  ContextImage,
  ContentItem,
  ContentTextItem,
} from './styled';

const getTitle = (key) => {
  switch (key) {
    case 1:
      return (
        <ContentTitle>
          Sistemi di sicurezza di ultima generazione strettamente integrati con il networking aziendale.
        </ContentTitle>
      );
    case 2:
      return <ContentTitle>Soluzioni software per il commercio al dettaglio e la ristorazione</ContentTitle>;
    case 3:
      return (
        <ContentTitle>
          Offriamo un ampio portafoglio di servizi gestiti per aiutare la tua azienda:
        </ContentTitle>
      );
    default:
      return null;
  }
};

const renderContentText = (key) => {
  switch (key) {
    case 1:
      return (
        <Fragment>
          <ContentText mt={30} mb={20}>
            Sicurezza e resilienza sono componenti fondamentali per ogni azienda: ti aiutiamo a proteggere i tuoi dati e le tue informazioni con la nostre soluzioni di Business Continuity.
          </ContentText>
          <ContentText>
            Grazie alla nostra esperienza, consolidata attraverso importanti
            partnership, e le nostre tecnologie avanzate, siamo in grado di offrire alta
            livelli di sicurezza contro le innumerevoli minacce di Internet.
          </ContentText>
        </Fragment>
      );
    case 2:
      return (
        <Fragment>
          <ContentText mt={30} mb={20}>
            Grazie alla nostra rete di sviluppatori qualificati, disponiamo di una solida base di soluzioni già pronte che possono essere adattate alle vostre esigenze aziendali.{' '}
          </ContentText>
          <ContentText>
            Tutto il nostro software è basato su cloud e non richiede alcuna infrastruttura aggiuntiva per essere eseguito.{' '}
          </ContentText>
        </Fragment>
      );
    case 3:
      return (
        <ContentText mt={30}>
          <ContentTextItem>
            <span />
            <p>Ridurre i costi di avvio e  di gestione dell'infrastruttura IT</p>
          </ContentTextItem>
          <ContentTextItem>
            <span />
            <p>Accedere a soluzioni costantemente aggiornate e ottimizzate</p>
          </ContentTextItem>
          <ContentTextItem>
            <span />
            <p>
              Accelerare la sperimentazione di nuovi business grazie al una logica data-driven
            </p>
          </ContentTextItem>
          <ContentTextItem>
            <span />
            <p>Sfruttare appieno solo le applicazioni davvero utili</p>
          </ContentTextItem>
          <ContentTextItem>
            <span />
            <p>
              Godere di una maggiore flessibilità e libertà nel passaggio da un fornitore all'altro
            </p>
          </ContentTextItem>
        </ContentText>
      );
    default:
      return null;
  }
};

const getImage = (key) => {
  switch (key) {
    case 1:
      return EnterpriseImg;
    case 2:
      return SoftwareImg;
    case 3:
      return ManageCloudImg;
    default:
      return null;
  }
};

const TabSwitcher = ({ onClick, activeKey }) => {
  const [loadedStatuses, setLoadedStatuses] = useState({
    1: false,
    2: false,
    3: false,
  });

  const onLoading = useCallback((key) => {
    setLoadedStatuses((state) => ({
      ...state,
      [key]: true,
    }));
  }, []);

  return (
    <Wrapper>
      <StyledContainer>
        <SwitchTabWrapper>
          <SwitchTabItem
            active={activeKey === 1}
            role='presentation'
            onClick={() => onClick(1)}
          >
            <span>Networking</span>
          </SwitchTabItem>
          <SwitchTabItem
            active={activeKey === 2}
            role='presentation'
            onClick={() => onClick(2)}
          >
            <span>Sviluppo software</span>
          </SwitchTabItem>
          <SwitchTabItem
            active={activeKey === 3}
            role='presentation'
            onClick={() => onClick(3)}
          >
            <span>Cloud gestito</span>
          </SwitchTabItem>
        </SwitchTabWrapper>
      </StyledContainer>
      <StyledContainer>
        <ContentWrapper>
          <ContentItem maxW='630px'>
            {getTitle(activeKey)} {renderContentText(activeKey)}
          </ContentItem>
          <ContentItem rightSide={true}>
            <ContextImage
              src={
                loadedStatuses[activeKey] ? getImage(activeKey) : LoadingImage
              }
              onLoad={() => onLoading(activeKey)}
            />
          </ContentItem>
        </ContentWrapper>
      </StyledContainer>
    </Wrapper>
  );
};

export default TabSwitcher;
