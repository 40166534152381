import React from 'react';
import { Link } from 'react-scroll';
import { domIds } from 'common/constants';
import { Navigation, ListItem, List } from './styled';

const HeaderNav = ({ open, onClose }) => {
  return (
    <Navigation open={open}>
      <List>
        <ListItem
          spy={true}
          smooth={true}
          // offset={-200}
          duration={500}
          to={domIds.welcome}
          activeClass='header_active'
          onClick={onClose}
          style={{ cursor: 'pointer' }}
        >
          {/*<ListItem>About Us</ListItem>*/}
          Chi siamo
        </ListItem>
        <ListItem
          spy={true}
          smooth={true}
          // offset={-200}
          duration={500}
          to={domIds.expertise}
          activeClass='header_active'
          onClick={onClose}
          style={{ cursor: 'pointer' }}
        >
          {/*<ListItem>Expertise</ListItem>*/}
          COMPETENZE
        </ListItem>
        <ListItem
          spy={true}
          smooth={true}
          // offset={-200}
          duration={500}
          to={domIds.projects}
          activeClass='header_active'
          onClick={onClose}
          style={{ cursor: 'pointer' }}
        >
          {/*<ListItem>Projects</ListItem>*/}
          Login ecommerce
        </ListItem>
        {/*<Link
          spy={true}
          smooth={true}
          duration={500}
          to={domIds.customers}
          activeClass='header_active'
        >
          <ListItem>Partners</ListItem>
        </Link>*/}
        <ListItem
          spy={true}
          smooth={true}
          duration={500}
          to={domIds.customers}
          activeClass='header_active'
          onClick={onClose}
          style={{ cursor: 'pointer' }}
        >
          {/*<ListItem>Customers</ListItem>*/}
          I clienti
        </ListItem>
        <ListItem
          spy={true}
          smooth={true}
          duration={500}
          to={domIds.contact_us}
          activeClass='header_active'
          onClick={onClose}
          style={{ cursor: 'pointer' }}
        >
          {/*<ListItem>Contact Us</ListItem>*/}
          Contattaci
          </ListItem>
      </List>
    </Navigation>
  );
};

export default HeaderNav;
